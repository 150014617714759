import { createContext, useContext, ReactNode } from 'react'

export enum LocalStorageKey {
    user = 'billing-user',
}

export function loadFromLocalStorage<T>(key: LocalStorageKey): T | undefined {
    const json = localStorage.getItem(key)
    if (json) {
        return JSON.parse(json)
    }
    return undefined
}
export function persistToLocalStorage(key: LocalStorageKey, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
}
export function removeFromLocalStorage(key: LocalStorageKey) {
    localStorage.removeItem(key)
}

interface LocalStorageContext {
    load<T>(key: LocalStorageKey): T | undefined
    persist(key: LocalStorageKey, value: any): void
    remove(key: LocalStorageKey): void
}

const localStorageHandler: LocalStorageContext = {
    load: loadFromLocalStorage,
    persist: persistToLocalStorage,
    remove: removeFromLocalStorage,
}

const LocalStorageContext = createContext<LocalStorageContext>(localStorageHandler)
export function LocalStorageProvider({ children }: { children: ReactNode }) {
    return <LocalStorageContext.Provider value={localStorageHandler}>{children}</LocalStorageContext.Provider>
}
export function useLocalStorage() {
    return useContext(LocalStorageContext)
}
