import Button, { type ButtonProps } from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

interface ButtonWithLoadingIndicatorProps {
    title: string
    isLoading: boolean
}
export function ButtonWithLoadingIndicator({
    title,
    isLoading,
    ...props
}: ButtonWithLoadingIndicatorProps & Omit<ButtonProps, 'variant'>) {
    const makeSpinner = (isVisible: boolean) => (
        <CircularProgress style={{ visibility: isVisible ? 'visible' : 'hidden', padding: '8px' }} />
    )
    return (
        <Button {...props} variant="contained" disabled={props.disabled || isLoading}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {/* A hidden CircularProgress for layout purposes */}
                {makeSpinner(false)}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexGrow: 1,
                        flexShrink: 0,
                    }}
                >
                    <Typography>{title}</Typography>
                </div>
                {makeSpinner(isLoading)}
            </div>
        </Button>
    )
}
