import { GenericHttpError } from 'common/http/httpError'
import { InvalidParameter } from 'common/errors'

export function formatError(error: any) {
    if (typeof error === 'string') {
        return error
    }
    if (error instanceof GenericHttpError) {
        if (typeof error.body === 'string') {
            return error.body
        }
        const title = error.body.title ?? ''
        let details: string = ''
        if (typeof error.body.detail === 'string') {
            details = error.body.detail
        } else if (Array.isArray(error.body.detail)) {
            details = error.body.detail.map(formatErrorObject).join(', ')
        } else if (typeof error.body.detail === 'object') {
            details = formatErrorObject(error.body.detail)
        }
        return `${error.status} - ${title}${details ? ` ${details}.` : ''}`
    } else if (error.message && typeof error.message === 'string') {
        return error.message
    }
    const json = JSON.stringify(error)
    if (json && json !== '{}') {
        return json
    }
    return `Unknown reason`
}

function formatErrorObject(errorObject: object) {
    const isInvalidParameterError = (obj: object): obj is InvalidParameter =>
        'name' in obj &&
        typeof (obj as { name: any }).name === 'string' &&
        'reason' in obj &&
        typeof (obj as { reason: any }).reason === 'string'

    if (isInvalidParameterError(errorObject)) {
        return errorObject.reason
    }
    return Object.values(errorObject).join(', ')
}
