import { useEffect, useState } from 'react'

import type { Query } from 'common/query'
import type { ListResult, SortOrder } from 'common/api/v1/types'

export type PaginatedDataFetchingFilter<T extends { [filterName: string]: boolean }> = {
    searchString?: string
    boolFilters?: T
}
interface PaginatedDataFetchingProps<
    Item,
    SortBy extends string,
    BoolFilter extends { [filterName: string]: boolean }
> {
    api: (query: Query<PaginatedDataFetchingFilter<BoolFilter>, SortOrder<SortBy>>) => Promise<ListResult<Item>>
    currentPage: number
    rowsPerPage: number
    searchString?: PaginatedDataFetchingFilter<BoolFilter>['searchString']
    boolFilters?: PaginatedDataFetchingFilter<BoolFilter>['boolFilters']
    sortOrder?: SortOrder<SortBy>
}
export function usePaginatedDataFetching<
    Item,
    SortBy extends string,
    BoolFilter extends { [filterName: string]: boolean }
>({
    api,
    currentPage,
    rowsPerPage,
    searchString,
    boolFilters,
    sortOrder,
}: PaginatedDataFetchingProps<Item, SortBy, BoolFilter>) {
    const [listResult, setListResult] = useState<ListResult<Item>>({ items: [], total: 0 })
    const [isFetching, setIsFetching] = useState(true)
    const [forceRefresh, setForceRefresh] = useState(false)
    const refreshData = () => setForceRefresh(!forceRefresh)

    useEffect(() => {
        async function doFetch() {
            setIsFetching(true)

            const query: Query<PaginatedDataFetchingFilter<BoolFilter>, SortOrder<SortBy>> = {
                filter: { searchString, boolFilters },
                skip: currentPage * rowsPerPage,
                limit: rowsPerPage,
                order: sortOrder,
            }
            try {
                const response = await api(query)
                // Fixes issue where listResult.total sometimes is suffixed with a character, e.g. '8n'
                const total = Number.parseInt(response.total.toString())
                setListResult({ ...response, total })
            } catch (error) {
                // Swallow the error and do nothing - it is the responsibility of the client (i.e. the function provided as 'api' parameter) to handle the errors,
                // e.g. by showing an appropriate UI, performing a retry followed by a call to refreshData().
                // eslint-disable-next-line
                console.log(`usePaginatedDataFetching doFetchError: `, error)
            }
            setIsFetching(false)
        }
        void doFetch()
    }, [
        currentPage,
        rowsPerPage,
        sortOrder?.field,
        sortOrder?.descending,
        ...Object.values(boolFilters ?? {}),
        searchString,
        forceRefresh,
    ])

    return { ...listResult, isFetching, refreshData }
}
