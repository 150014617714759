import { addMinutes, parseISO } from 'date-fns'

export const ONE_MINUTE_IN_MS = 60_000

/// Returns a UTC date with format 'yyyy-MM-dd HH:mm'
export function formatDateTime(d: Date): string {
    return d.toISOString().substring(0, 16).replace('T', ' ')
}

/// Returns a UTC date with format 'yyyy-MM-dd'
export function formatDate(d: Date): string {
    return d.toISOString().substring(0, 10)
}

export function parseISOUTC(s: string): Date {
    const d = parseISO(s)
    return addMinutes(d, d.getTimezoneOffset())
}

export function utcToLocal(d: Date): Date {
    return addMinutes(d, -d.getTimezoneOffset())
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
