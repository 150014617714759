import { Location, NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom'

export interface NavigationState {
    from?: Location
}
export function useStatefulNavigate() {
    const location = useLocation()
    const reactRouterNavigate = useNavigate()

    const navigateBack = (options?: NavigateOptions) => {
        const cameFromUrl = cameFrom(location)
        if (cameFromUrl) {
            navigate(cameFromUrl, options)
        } else {
            reactRouterNavigate(-1)
        }
    }

    const navigate = (to: To, optionsOverrides?: NavigateOptions) => {
        const navigationState: NavigationState = { from: location }
        const options: NavigateOptions = {
            state: optionsOverrides?.state ?? navigationState,
            replace: optionsOverrides?.replace ?? false,
        }
        reactRouterNavigate(to, options)
    }

    return { navigate, navigateBack }
}

export function cameFrom(location: Location): string | undefined {
    const source = (location.state as NavigationState)?.from
    if (!source) return undefined
    return `${source.pathname}${source.search}`
}
