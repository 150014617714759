import { Role } from './types'

const roleLevels = { [Role.invoiceOnly]: 1, [Role.licenseAdmin]: 2, [Role.admin]: 3, [Role.super]: 4 }

function isRole(r?: Role): r is Role {
    if (typeof r === 'undefined') {
        return false
    }
    if (!Object.values(Role).includes(r)) {
        throw new Error(`Invalid role: ${r}`)
    }
    return true
}

export function maxRole(...roles: (Role | undefined)[] & { 0: Role }) {
    const definedRoles = roles.filter(isRole)
    if (definedRoles.length < 1) {
        throw new Error(`No valid role received`)
    }
    let role: Role = definedRoles[0]
    for (const r of definedRoles) {
        if (roleLevels[r] > roleLevels[role]) {
            role = r
        }
    }
    return role
}

export function isRoleAuthorized({
    role,
    minimumAllowedRole,
    strict,
}: {
    role: Role
    minimumAllowedRole: Role
    /**
     * If `strict` is `true` only allow roles above `minimumAllowedRole`
     */
    strict?: boolean
}): boolean {
    const roleLevel = roleLevels[role]
    const minimumAllowedLevel = roleLevels[minimumAllowedRole]
    if (!roleLevel || !minimumAllowedLevel) {
        throw new Error(`isRoleAuthorized: Invalid roles: ${role} ${minimumAllowedRole}`)
    }
    if (strict) return roleLevel > minimumAllowedLevel
    return roleLevel >= minimumAllowedLevel
}
