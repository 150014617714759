import { SyntheticEvent, useEffect, useState } from 'react'
import { useMatch, useParams, useResolvedPath } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { PRODUCTS as products } from 'common/constants'
import { useBillingApi } from '../../api/billing/billing-context'
import { ValidatedInput } from '../common/InputWithValidation'
import { findLicenseTypeById } from 'common-billing-server/index'
import {
    CreditType,
    LicenceInvalidationReason,
    License,
    LICENSE_TYPES as licenseTypes,
    LicenseType,
    NewLicense,
} from 'common-billing-server/types'
import { Path } from '../../routes/path'
import { notEmpty } from '../../validators'
import { commonTextFieldProps } from '../../theme/common-styles'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { ButtonWithLoadingIndicator } from '../common/ButtonWithLoadingIndicator'
import { useStatefulNavigate } from '../common/hooks/hook-stateful-navigate'
import { AutoComplete } from '../common/AutoComplete'
import { SearchParamKey } from '../common/Table'
import { LicenseInstallations } from './LicenseInstallations'
import { ConnectitEntitlements, Product } from 'common/api/v1/types'
import { listResult } from '../../list-result'

export const CreateEditLicensePage = () => {
    const billingApi = useBillingApi()
    const { navigateBack, navigate } = useStatefulNavigate()
    const urlParams = useParams()
    const licenseId: string | undefined = urlParams.licenseId
    const resolvedPath = useResolvedPath(Path.editLicense)
    const isEditingLicense = !!useMatch({ path: resolvedPath.pathname, end: true }) && !!licenseId

    const [customer, setCustomer] = useState<License['customer'] | null>(null)
    const [purpose, setPurpose] = useState('')
    const [domain, setDomain] = useState('')
    const [product, setProduct] = useState<Product>(products.nimbraEdge)
    const [maxTokens, setMaxTokens] = useState<number>(100)
    const [expiresAt, setExpiresAt] = useState('')
    const [licenseType, setLicenseType] = useState<LicenseType>(licenseTypes.usage)
    const [creditType, setCreditType] = useState(CreditType.total)
    const [licenseKey, setLicenseKey] = useState('')
    const [invalidationReason, setInvalidationReason] = useState<LicenceInvalidationReason | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [hasSubmittedForm, setHasSubmittedForm] = useState(false)
    const [validationErrors, setValidationErrors] = useState({
        customer: 'Cannot be empty' as string | undefined,
        purpose: undefined as string | undefined,
        domain: undefined as string | undefined,
    })
    const updateValidationError = (key: keyof typeof validationErrors, value: string | undefined) => {
        setValidationErrors((prevState) => ({ ...prevState, [key]: value }))
    }
    const formContainsInvalidInput = Object.values(validationErrors).some((error) => error)

    const { addFailedOperation, removeFailedOperation, errorBanner } = useFailedOperationBanner()

    useEffect(() => {
        const operationId = 'fetch-license'

        async function fetchLicense(id: string) {
            removeFailedOperation(operationId)
            setIsLoading(true)
            try {
                const response = await billingApi.listLicenses({ filter: { licenseIds: [id] } })
                const license = response.items[0]
                if (!license) {
                    throw new Error(`No license found with id '${id}'`)
                }
                setCustomer(license.customer)
                setProduct(findProduct(license.product))
                setLicenseType(findLicenseTypeById(license.licenseType)!)
                setCreditType(license.creditType)
                updateValidationError('customer', undefined)
                setLicenseKey(license.licenseKey)
                setPurpose(license.purpose)
                setDomain(license.domain || '')
                if (license.entitlements) {
                    setMaxTokens((license.entitlements as ConnectitEntitlements).maxTokens)
                }
                setExpiresAt(
                    (license.entitlements as ConnectitEntitlements)?.expiresAt?.toISOString().substring(0, 10) || ''
                )

                setInvalidationReason(license.invalidationReason)
            } catch (error) {
                addFailedOperation({
                    id: operationId,
                    message: 'Failed fetching license',
                    error,
                })
            }
            setIsLoading(false)
        }

        if (isEditingLicense) {
            void fetchLicense(licenseId)
        }
    }, [licenseId, isEditingLicense])

    const createOrUpdateLicense = async (event: SyntheticEvent) => {
        event.preventDefault()

        const operationId = 'update-license'
        removeFailedOperation(operationId)
        setHasSubmittedForm(true)
        if (formContainsInvalidInput) {
            return
        }
        try {
            setIsLoading(true)
            const license: NewLicense = {
                customer: customer!.id,
                purpose,
                domain,
                product: product.id,
                licenseType: licenseType.id,
                creditType,
                entitlements: {
                    product: product.id,
                    maxTokens,
                    expiresAt: parseAsDateOrUndefined(expiresAt),
                },
            }
            if (isEditingLicense) {
                // TODO: If we allow editing a license: await billingApi.updateLicense(licenseId!, license)
                navigateBack({ replace: true })
            } else {
                const createdLicense = await billingApi.createLicense(license)
                navigate(
                    {
                        pathname: Path.licenses,
                        search: `${SearchParamKey.searchString}=${createdLicense.licenseKey}`,
                    },
                    { replace: true }
                )
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            addFailedOperation({
                id: operationId,
                message: `Failed ${isEditingLicense ? 'updating' : 'creating'} license`,
                error,
            })
        }
    }

    const showMaxTokensField = product.id === products.connectIt.id
    const showExpirationField = product.id === products.connectIt.id

    return (
        <>
            <Toolbar>
                <Typography variant="h6" component="div">
                    {isEditingLicense ? 'View' : 'Create'} license
                </Typography>
            </Toolbar>

            <Paper style={{ margin: '20px' }}>
                <form
                    onSubmit={(e) => void createOrUpdateLicense(e)}
                    style={{
                        padding: '20px',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <AutoComplete<License['customer']>
                        placeholder={`Customer`}
                        value={customer}
                        isRequired={true}
                        onValueSelected={(selected) => {
                            if (!selected) {
                                updateValidationError('customer', 'Cannot be empty.')
                            } else if (validationErrors.customer) {
                                updateValidationError('customer', undefined)
                            }
                            setCustomer(selected)
                        }}
                        formatSelectedValue={(customer) => customer.name}
                        error={hasSubmittedForm ? validationErrors.customer : undefined}
                        isClearable={false}
                        api={(query) => billingApi.listCustomers({ ...query, filter: { searchName: query.filter } })}
                        isDisabled={isLoading || isEditingLicense}
                        dataTestId={'license-customer'}
                    />

                    {isEditingLicense && (
                        <>
                            <TextField
                                {...commonTextFieldProps()}
                                type="text"
                                label={'License key'}
                                disabled={true}
                                value={licenseKey}
                            />

                            <TextField
                                {...commonTextFieldProps()}
                                type="text"
                                label={'Status'}
                                disabled={true}
                                value={invalidationReason || 'valid'}
                            />
                        </>
                    )}

                    <ValidatedInput
                        {...commonTextFieldProps()}
                        type="text"
                        label={'Purpose'}
                        disabled={isLoading || isEditingLicense}
                        validators={[notEmpty]}
                        value={purpose}
                        onChange={setPurpose}
                        onValidationError={(error) => updateValidationError('purpose', error)}
                        showErrorDespiteUntouched={hasSubmittedForm}
                    />

                    <ValidatedInput
                        {...commonTextFieldProps()}
                        type="text"
                        label={'Domain'}
                        placeholder={'edge.example.com'}
                        disabled={isLoading || isEditingLicense}
                        validators={[]}
                        value={domain}
                        onChange={setDomain}
                        onValidationError={(error) => updateValidationError('domain', error)}
                        showErrorDespiteUntouched={hasSubmittedForm}
                        helperText={
                            product === products.connectIt &&
                            !(domain.endsWith('.cnctit.io') || domain.endsWith('.nimbra.dev'))
                                ? 'Only .cnctit.io sub-domains will be automatically managed'
                                : ''
                        }
                    />

                    <AutoComplete<Product>
                        placeholder={`Product`}
                        value={product}
                        isRequired={true}
                        onValueSelected={(selected) => {
                            setProduct(selected!)
                        }}
                        formatSelectedValue={(product) => product.name}
                        error={hasSubmittedForm ? validationErrors.customer : undefined}
                        isClearable={false}
                        api={(query) => Promise.resolve(listResult([products.connectIt, products.nimbraEdge]))}
                        isDisabled={isLoading || isEditingLicense}
                        dataTestId={'product-customer'}
                    />

                    <AutoComplete<LicenseType>
                        placeholder={`License type`}
                        value={licenseType}
                        isRequired={true}
                        onValueSelected={(selected) => {
                            setLicenseType(selected!)
                        }}
                        formatSelectedValue={(product) => product.name}
                        error={hasSubmittedForm ? validationErrors.customer : undefined}
                        isClearable={false}
                        api={(query) => Promise.resolve(listResult(Object.values(licenseTypes)))}
                        isDisabled={isLoading || isEditingLicense}
                        dataTestId={'license-type-customer'}
                    />

                    <AutoComplete<{ id: CreditType; name: string }>
                        isRequired={true}
                        placeholder={`Contracted stream credits`}
                        value={{ id: creditType, name: creditType }}
                        onValueSelected={(selected) => setCreditType(selected?.id ?? CreditType.total)}
                        formatSelectedValue={(selected) => selected.name}
                        isClearable={false}
                        isDisabled={isLoading || isEditingLicense}
                        api={() =>
                            Promise.resolve(listResult(Object.values(CreditType).map((v) => ({ id: v, name: v }))))
                        }
                        dataTestId={'charged-credit-type'}
                    />

                    {showMaxTokensField && (
                        <TextField
                            {...commonTextFieldProps()}
                            type="number"
                            label={'Max tokens'}
                            disabled={isLoading || isEditingLicense}
                            value={maxTokens}
                            onChange={(e) => setMaxTokens(parseInt(e.target.value))}
                        />
                    )}

                    {showExpirationField && (
                        <TextField
                            {...commonTextFieldProps()}
                            type="date"
                            label={'Expiration date'}
                            disabled={isLoading || isEditingLicense}
                            value={expiresAt}
                            onChange={(e) => setExpiresAt(e.target.value)}
                        />
                    )}

                    {!isEditingLicense && (
                        <ButtonWithLoadingIndicator
                            title={isEditingLicense ? 'Update' : 'Create'}
                            type="submit"
                            isLoading={isLoading}
                            disabled={formContainsInvalidInput || isLoading}
                            style={{ width: '25%', margin: '12px 0px 20px 0px' }}
                        />
                    )}

                    {errorBanner}
                </form>
            </Paper>
            {licenseKey && (
                <>
                    <Toolbar>
                        <Typography variant="h6">Installations using this license</Typography>
                    </Toolbar>
                    <Paper style={{ margin: '0px 20px 20px 20px' }}>
                        <LicenseInstallations licenseKey={licenseKey} />
                    </Paper>
                </>
            )}
        </>
    )
}

function findProduct(productId: string): Product {
    return Object.values(products).find((product) => product.id === productId)!
}

function parseAsDateOrUndefined(dateString: string): Date | undefined {
    const date = new Date(dateString)
    return isNaN(date.valueOf()) ? undefined : date
}
