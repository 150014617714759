import { BillingClient, RequestParams } from 'common-billing-server/generated/billingClient'
import { RequestOptions, RequestResult } from 'common/network/request-options'
import { GenericHttpError } from 'common/http/httpError'
import { jsonParse, jsonStringify } from 'common/serialization'

class BrowserBillingClient extends BillingClient {
    constructor(params: { baseUrl: string; apiKey?: string }) {
        super(params)
    }
    protected async _request<T>({
        method,
        url,
        body,
        optionsOverrides,
    }: {
        method: string
        url: string
        body?: any
        optionsOverrides?: Partial<RequestOptions>
    }): Promise<RequestResult<T>> {
        const headers = optionsOverrides?.headers ?? {}
        const newHeaders = Object.keys(headers).reduce((acc, key) => {
            const value = headers[key]!
            acc[key] = Array.isArray(value) ? value.join(',') : value
            return acc
        }, {} as { [key: string]: string })

        const inferredHeaders = {
            'x-request-id': window.crypto.randomUUID(),
            'Content-Type': 'application/json',
        }
        const requestInit: RequestInit = {
            method,
            headers: new Headers({
                ...inferredHeaders,
                ...newHeaders,
            }),
            body: JSON.stringify(body),
            credentials: 'include',
        }

        try {
            const response: Response = await fetch(url, requestInit)
            let data = {}
            try {
                data = await response.json()
            } catch (e) {
                const contentLength = Number(response.headers.get('content-length'))
                if (!isNaN(contentLength) && contentLength > 0) {
                    //eslint-disable-next-line no-console
                    console.log(`Failed parsing response data with expected content length: ${contentLength}`, e)
                }
                data = {}
            }
            const headers: { [key: string]: string } = {}
            for (const pair of response.headers.entries()) {
                headers[pair[0]] = pair[1]
            }
            if ((optionsOverrides?.throwOnNon200 ?? true) && (response.status < 200 || response.status > 300)) {
                throw new GenericHttpError(response.status, data, headers)
            }
            return {
                body: jsonParse(jsonStringify(data)),
                headers,
                status: response.status,
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`Error: performing '${method.toUpperCase()}' towards '${url}': `, e)
            throw e
        }
    }
}

export class BillingServerApi extends BrowserBillingClient {
    onRequestErrorFn: ((error: GenericHttpError) => void) | undefined = undefined

    protected async _request<T>(params: RequestParams): Promise<RequestResult<T>> {
        try {
            return await super._request(params)
        } catch (genericHttpError) {
            this.onRequestErrorFn?.(genericHttpError)
            throw genericHttpError
        }
    }
}
