import { useEffect, useState } from 'react'
import TextField, { type TextFieldProps } from '@mui/material/TextField'

import { notEmpty, Validator } from '../../validators'
import { minHeight } from '../../theme/common-styles'

interface ValidationProps {
    value: string
    onChange: (value: string) => void
    validators: Validator[]
    onValidationError: (error: string | undefined) => void
    showErrorDespiteUntouched?: boolean
}

export const ValidatedInput = ({
    value,
    onChange,
    validators,
    onValidationError,
    showErrorDespiteUntouched,
    ...props
}: ValidationProps & Omit<TextFieldProps, 'onChange'>) => {
    const [touched, setTouched] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)

    useEffect(() => {
        function validate(value: string): string | undefined {
            const failingValidator = validators.find((validator) => {
                const isValidInput = validator.validate(value)
                return !isValidInput
            })
            return failingValidator?.errorMessage
        }

        const newError = validate(value)
        if (newError !== error) {
            setError(newError)
            onValidationError(newError)
        }
    }, [value, validators, onValidationError, error])

    const handleChange = (newValue: string) => {
        if (!touched) setTouched(true)
        onChange(newValue)
    }

    const isRequired = validators.includes(notEmpty)
    const shouldShowError = touched || showErrorDespiteUntouched

    const margin: 'normal' | 'dense' = 'normal'
    const style = {
        ...(props.style ?? {}),
        // This minHeight is required to avoid a "jumping" UI on error (when the helperText is displayed)
        minHeight: minHeight(margin),
    }
    return (
        <TextField
            {...props}
            margin={margin as TextFieldProps['margin']}
            style={style}
            required={isRequired}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            error={!!shouldShowError && !!error}
            helperText={(shouldShowError && error) || props.helperText || ''}
        />
    )
}
