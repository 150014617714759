import { useEffect, useState } from 'react'

export function useDebounce<S>(value: S, delayMs: number = 500) {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        if (delayMs > 0) {
            const handler = setTimeout(() => setDebouncedValue(value), delayMs)
            return () => clearTimeout(handler)
        } else {
            setDebouncedValue(value)
        }
    }, [value, delayMs])

    return debouncedValue
}
