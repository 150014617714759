import { SyntheticEvent } from 'react'
import { ButtonWithLoadingIndicator } from '../common/ButtonWithLoadingIndicator'
import { FailedOperationHook } from '../common/hooks/hook-failed-operation'
import { useBillingApi } from '../../api/billing/billing-context'

interface Props {
    failedOperationHook: FailedOperationHook
    isLoadingState: {
        isLoading: boolean
        setIsLoading: (newValue: boolean) => void
    }
}

export const AzureLoginPage = ({ failedOperationHook, isLoadingState }: Props) => {
    const billingApi = useBillingApi()
    const { isLoading, setIsLoading } = isLoadingState

    const { addFailedOperation, removeFailedOperation } = failedOperationHook

    const beginLoginFlow = async (event: SyntheticEvent) => {
        event.preventDefault()
        if (isLoading) {
            return
        }
        const operationId = 'fetchLoginUrl'
        removeFailedOperation(operationId)

        try {
            setIsLoading(true)
            window.location.href = await billingApi.getSsoAzureLoginUrl()
        } catch (error) {
            addFailedOperation({
                id: operationId,
                message: 'Failed fetching login url',
                error,
            })
        }
        setIsLoading(false)
    }

    return (
        <ButtonWithLoadingIndicator
            title="Log in via Azure"
            onClick={(e) => void beginLoginFlow(e)}
            fullWidth
            isLoading={isLoading}
            disabled={isLoading}
            style={{ margin: '12px 0px' }}
        />
    )
}
