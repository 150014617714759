const regexEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
const regexNumeric = new RegExp('^[0-9]*$')

export interface Validator {
    readonly errorMessage: string
    validate(input: string): boolean
}

export const email: Validator = {
    errorMessage: 'Must be a valid email address.',
    validate: (input: string) => !input || regexEmail.test(input),
}
export const notEmpty: Validator = {
    errorMessage: 'Cannot be empty.',
    validate: (input: string) => !!input && input.length > 0,
}
export const numeric: Validator = {
    errorMessage: 'Must be numeric.',
    validate: (input: string) => !input || regexNumeric.test(input),
}
export class MinLength implements Validator {
    errorMessage: string
    constructor(private minimumAllowedLength: number) {
        this.errorMessage = `Must contain at least ${minimumAllowedLength} characters.`
    }
    validate(input: string) {
        return !input || input.length >= this.minimumAllowedLength
    }
}
