import { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

const spinDurationSeconds = 2

const classes = {
    rotating: {
        animation: `spin ${spinDurationSeconds}s linear 1`,
        '@keyframes spin': {
            '100%': {
                transform: 'rotate(-360deg)',
            },
        },
    },
}

interface Props {
    tooltip?: string
    onClick: () => void
    Component: JSX.Element
}

export const RotatingButton = ({ tooltip, onClick, Component }: Props) => {
    const [isRotating, setIsRotating] = useState(false)

    useEffect(() => {
        const timerId = setTimeout(() => isRotating && setIsRotating(false), spinDurationSeconds * 1000)
        return () => clearTimeout(timerId)
    }, [isRotating])

    const handleClick = () => {
        setIsRotating(true)
        onClick()
    }

    return (
        <Tooltip title={tooltip ?? ''}>
            <IconButton disabled={isRotating} onClick={handleClick} sx={isRotating ? classes.rotating : undefined}>
                {Component}
            </IconButton>
        </Tooltip>
    )
}
