import { useAuth } from './Auth'
import { useEffect } from 'react'
import { Path } from '../../routes/path'
import { Navigate } from 'react-router-dom'
import { useConfirmationDialog } from '../common/ConfirmationDialog'
import { useStatefulNavigate } from '../common/hooks/hook-stateful-navigate'

export const LogoutPage = () => {
    const { navigateBack } = useStatefulNavigate()
    const { user, logout } = useAuth()
    const isLoggedIn = !!user

    const showConfirmLogoutDialogFn = useConfirmationDialog()
    useEffect(() => {
        const cancelLogout = () => navigateBack({ replace: true })
        showConfirmLogoutDialogFn(
            () => {
                void logout()
            },
            'Are you sure you want to sign out?',
            { ok: { text: 'Sign out' } },
            cancelLogout
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>{!isLoggedIn && <Navigate to={Path.login} replace />}</>
}
