import { FunctionComponent, ChangeEvent } from 'react'
import InputBase from '@mui/material/InputBase'
import Search from '@mui/icons-material/Search'
import { alpha, styled } from '@mui/material/styles'

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '10px',
    borderRadius: (theme.shape.borderRadius as number) * 50, // TODO: Don't use as
    backgroundColor: alpha(theme.palette.common.white, theme.palette.mode === 'dark' ? 0.1 : 1),
}))

export interface SearchInputProps {
    initialValue?: string
    onChange: (input: string) => void
    placeholder?: string
}

/**
 * Component with no logic to show special 'search' input
 * @param initialValue - the initial value for the input
 * @param onChange - callback to call on value _change_
 * @param placeholder - the text inside the empty input
 */
const SearchInput: FunctionComponent<SearchInputProps> = ({ initialValue, onChange, placeholder = 'Search...' }) => {
    const onInputChangeHandler = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onChange(event.target.value)
    }
    return (
        <Root>
            <Search style={{ opacity: 0.6 }} />
            <InputBase
                value={initialValue}
                onChange={onInputChangeHandler}
                placeholder={placeholder}
                fullWidth={true}
            />
        </Root>
    )
}
export default SearchInput
