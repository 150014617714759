import { CSSProperties } from 'react'
import { createTheme } from '@mui/material/styles'

const BlueLight = {
    50: '#E0ECFD',
    100: '#B2D1FF',
    200: '#7EAEF7',
    300: '#448AFA',
    400: '#1F71FF',
    500: '#0055FF',
    600: '#0041E5',
    700: '#0730C5',
    800: '#001EAA',
    900: '#052159',
}

const hStyles = [44, 36, 25, 20, 18, 16].reduce<{ [key: string]: CSSProperties }>(
    (acc, item, ind) => ({
        ...acc,
        [`h${ind + 1}`]: {
            fontFamily: "'Roboto slab', sans-serif",
            fontWeight: 'bold',
            fontSize: `${item}px`,
            lineHeight: 1.6,
        },
    }),
    {}
)

export const lightTheme = createTheme({
    palette: {
        background: {
            default: '#F5F5F5',
        },
        primary: {
            main: BlueLight[500],
            light: BlueLight[300],
            dark: BlueLight[900],
        },
        secondary: {
            main: BlueLight[400],
            light: BlueLight[200],
            dark: BlueLight[800],
        },
        error: {
            main: '#D4626F',
            dark: '#C0767E',
            light: '#E84E5F',
        },
        success: {
            main: '#79D0B7',
            dark: '#8BBEAF',
            light: '#67E2BF',
        },
        warning: {
            main: '#E8CD5C',
            dark: '#D5C16F',
            light: '#FAD84A',
        },
        contrastThreshold: 3,
    },
    typography: {
        ...hStyles,
        fontFamily: "'Roboto', sans-serif",
    },
    components: {
        MuiTooltip: {},
        MuiTableCell: {},
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#f1e8e8',
                    color: '#757575',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    backgroundColor: 'transparent' as const,
                    color: '#000000',
                },
            },
        },
        MuiListItem: {},
        MuiLink: {},
    },
})
