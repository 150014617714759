import { SyntheticEvent, useState } from 'react'
import { ValidatedInput } from '../common/InputWithValidation'
import { useAuth } from './Auth'
import { INITIAL_PATH } from '../../routes/path'
import { notEmpty } from '../../validators'
import { commonTextFieldProps } from '../../theme/common-styles'
import { ButtonWithLoadingIndicator } from '../common/ButtonWithLoadingIndicator'
import { FailedOperationHook } from '../common/hooks/hook-failed-operation'
import { useStatefulNavigate } from '../common/hooks/hook-stateful-navigate'

interface Props {
    failedOperationHook: FailedOperationHook
    isLoadingState: {
        isLoading: boolean
        setIsLoading: (newValue: boolean) => void
    }
}
export const UsernamePasswordLoginPage = ({ failedOperationHook, isLoadingState }: Props) => {
    const auth = useAuth()
    const { navigate } = useStatefulNavigate()
    const { isLoading, setIsLoading } = isLoadingState

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [hasSubmittedForm, setHasSubmittedForm] = useState(false)
    const [validationErrors, setValidationErrors] = useState({
        username: undefined as string | undefined,
        password: undefined as string | undefined,
    })
    const formContainsInvalidInput = Object.values(validationErrors).some((error) => error !== undefined)
    const updateFormErrors = (key: keyof typeof validationErrors, value: string | undefined) => {
        setValidationErrors((prevState) => ({ ...prevState, [key]: value }))
    }

    const { addFailedOperation, removeFailedOperation } = failedOperationHook

    const doLogin = async (event: SyntheticEvent) => {
        event.preventDefault()

        if (isLoading) {
            return
        }
        setHasSubmittedForm(true)
        const operationId = 'login'
        removeFailedOperation(operationId)

        if (formContainsInvalidInput) {
            return
        }
        try {
            setIsLoading(true)
            await auth.login(username, password)
            navigate(INITIAL_PATH, { replace: true })
        } catch (error) {
            addFailedOperation({
                id: operationId,
                message: 'Log in failed',
                error,
            })
        }

        setIsLoading(false)
    }

    return (
        <form
            onSubmit={(e) => void doLogin(e)}
            style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <ValidatedInput
                {...commonTextFieldProps()}
                required={true}
                type="text"
                label={'Username'}
                disabled={isLoading}
                autoFocus={true}
                validators={[notEmpty]}
                value={username}
                onChange={setUsername}
                onValidationError={(error) => updateFormErrors('username', error)}
                showErrorDespiteUntouched={hasSubmittedForm}
                inputProps={{
                    'data-test-id': 'username',
                }}
            />
            <ValidatedInput
                {...commonTextFieldProps()}
                required={true}
                type="password"
                label={'Password'}
                disabled={isLoading}
                autoComplete="current-password"
                validators={[notEmpty]}
                value={password}
                onChange={setPassword}
                onValidationError={(error) => updateFormErrors('password', error)}
                showErrorDespiteUntouched={hasSubmittedForm}
                inputProps={{
                    'data-test-id': 'password',
                }}
            />
            <ButtonWithLoadingIndicator
                title="Log in"
                type="submit"
                fullWidth
                isLoading={isLoading}
                disabled={formContainsInvalidInput || isLoading}
                style={{ margin: '12px 0px' }}
            />
        </form>
    )
}
