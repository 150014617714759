import { alpha, styled, Theme } from '@mui/material/styles'

export const commonTextFieldProps = () => ({
    variant: 'outlined' as const,
    margin: 'normal' as const,
    fullWidth: true,
    InputLabelProps: { shrink: true },
})

export const FlexFiller = styled('div')({
    flexGrow: 1,
})

export const classes = {
    toolbar: {},
    toolbarHighlighted: (theme: Theme) => ({
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
    }),
}

// This minHeight is required for TextFields to avoid a "jumping" UI on error (when the helperText is displayed)
export const minHeight = (margin: 'normal' | 'dense') => (margin === 'dense' ? '4rem' : '5rem')
