import { Role, User } from 'common-billing-server/types'
import { createContext, useContext, useEffect, useState, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { GenericHttpError, HttpStatusCode } from 'common/http/httpError'
import { INITIAL_PATH, Path } from '../../routes/path'
import { useBillingApi } from '../../api/billing/billing-context'
import { LocalStorageKey, useLocalStorage } from '../../local-storage'
import { cameFrom, NavigationState } from '../common/hooks/hook-stateful-navigate'
import { isRoleAuthorized } from 'common-billing-server/role'

interface AuthContextType {
    user?: User
    refreshUser: () => Promise<void>
    login: (username: string, password: string) => Promise<void>
    logout: () => Promise<void>
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export function AuthProvider({ children }: { children: ReactNode }) {
    const billingApi = useBillingApi()
    const localStorage = useLocalStorage()
    const [user, setUser] = useState(localStorage.load<User>(LocalStorageKey.user))

    useEffect(() => {
        billingApi.onRequestErrorFn = (httpError: GenericHttpError) => {
            if (httpError.status === HttpStatusCode.Unauthorized) {
                doRemovePersistedUser()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const login = async (username: string, password: string) => {
        const { user } = await billingApi.login({ username, password })
        doPersistUser(user)
    }

    const refreshUser = async () => {
        const user = await billingApi.getCurrentUser()
        doPersistUser(user)
    }

    const logout = async () => {
        doRemovePersistedUser()
        await billingApi.logout()
    }

    const doPersistUser = (user: User) => {
        setUser(user)
        localStorage.persist(LocalStorageKey.user, user)
    }

    const doRemovePersistedUser = () => {
        localStorage.remove(LocalStorageKey.user)
        setUser(undefined)
    }

    const value: AuthContextType = { user, refreshUser, login, logout }
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth(): AuthContextType {
    return useContext(AuthContext)
}

// Navigates to the login page if the user role is unauthenticated. Else navigates to the received child.
export function RequireAuth({ children }: { children: JSX.Element }) {
    const auth: AuthContextType = useAuth()
    const location = useLocation()
    if (!auth.user) {
        const navigationState: NavigationState = { from: location }
        return <Navigate to={Path.login} state={navigationState} replace />
    }
    return children
}

// Navigates back to where the user came from if the user role is unauthorized. Else navigates to the received child.
export function RequireRole({ minimumAllowedRole, children }: { minimumAllowedRole: Role; children: JSX.Element }) {
    const { user } = useAuth()
    const location = useLocation()
    if (!user || !isRoleAuthorized({ role: user.role, minimumAllowedRole })) {
        const navigationState: NavigationState = { from: location }
        const destination = cameFrom(location) || INITIAL_PATH
        return <Navigate to={destination} state={navigationState} replace />
    }
    return children
}
