import { useMemo, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Add from '@mui/icons-material/Add'
import Cached from '@mui/icons-material/Cached'
import Delete from '@mui/icons-material/Delete'

import { findProductById } from 'common/constants'
import { useBillingApi } from '../../api/billing/billing-context'
import { findLicenseTypeById } from 'common-billing-server/index'
import { License, ListLicenseSortableField, Role } from 'common-billing-server/types'
import { makeEditCustomerPath, makeEditLicensePath, Path } from '../../routes/path'
import { Column, DataFetchingPaginatedTable, RefreshableDataFetchingPaginatedTable } from '../common/Table'
import { Query } from 'common/query'
import { SortOrder } from 'common/api/v1/types'
import { useConfirmationDialog } from '../common/ConfirmationDialog'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { NavigationState, useStatefulNavigate } from '../common/hooks/hook-stateful-navigate'
import { capitalizeFirstLetter, formatDateTime } from '../../format-date'
import { ListPageToolbar } from '../common/ListPageToolbar'
import { RoleBasedComponent, RoleBasedLink } from '../common/RoleBasedLink'
import { LicenseActions } from './LicenseActions'
import { RotatingButton } from '../common/RotatingButton'
import { PaginatedDataFetchingFilter } from '../common/hooks/hook-paginated-data-fetching'

export const ListLicensesPage = () => {
    const billingApi = useBillingApi()
    const { navigate } = useStatefulNavigate()
    const navigateToLicense = (license: License) => navigate(makeEditLicensePath(license.id))
    const tableRef = useRef<RefreshableDataFetchingPaginatedTable>(null)
    const [selectedLicenses, setSelectedLicenses] = useState<License[]>([])
    const showConfirmDeleteDialogFn = useConfirmationDialog()
    const location = useLocation()
    const { addFailedOperation, errorBanner } = useFailedOperationBanner()

    const columns: Column<License, ListLicenseSortableField>[] = useMemo(
        () => [
            { title: 'License key', valueForColumn: (l) => l.licenseKey, isClickable: false },
            {
                title: 'Customer',
                isClickable: false,
                valueForColumn: (l) => {
                    const navigationState: NavigationState = { from: location }
                    return (
                        <RoleBasedLink
                            minimumAllowedRole={Role.admin}
                            to={makeEditCustomerPath(l.customer.id)}
                            state={navigationState}
                        >
                            <div>{l.customer.name}</div>
                        </RoleBasedLink>
                    )
                },
                sortField: ListLicenseSortableField.customerName,
            },
            { title: 'Product', valueForColumn: (l) => findProductById(l.product)?.name },
            { title: 'Domain', valueForColumn: (l) => l.domain },
            { title: 'License type', valueForColumn: (l) => findLicenseTypeById(l.licenseType)?.name },
            { title: 'Purpose', valueForColumn: (l) => l.purpose },
            {
                title: 'Created at (UTC)',
                valueForColumn: (l) => formatDateTime(l.createdAt),
                sortField: ListLicenseSortableField.createdAt,
            },
            {
                title: 'Activated at (UTC)',
                valueForColumn: (l) => (l.activatedAt ? formatDateTime(l.activatedAt) : 'N/A'),
                sortField: ListLicenseSortableField.activatedAt,
            },
            {
                title: 'Status',
                valueForColumn: (l) => {
                    if (!l.invalidatedAt) return `Valid`
                    return `${capitalizeFirstLetter(l.invalidationReason ?? 'Invalidated')} at ${formatDateTime(
                        l.invalidatedAt
                    )} (UTC)`
                },
                sortField: ListLicenseSortableField.invalidatedAt,
            },
            {
                title: 'Actions',
                isClickable: false,
                valueForColumn: (l) => (
                    <LicenseActions
                        refreshTableFn={tableRef.current?.refreshData}
                        addFailedOperation={addFailedOperation}
                        license={l}
                    />
                ),
            },
        ],
        [location]
    )

    async function fetchLicenses({
        filter,
        order,
        ...query
    }: Query<PaginatedDataFetchingFilter<{ showRevoked: boolean }>, SortOrder<ListLicenseSortableField>>) {
        try {
            const showRevokedLicenses = filter?.boolFilters?.showRevoked ?? false
            return await billingApi.listLicenses({
                ...query,
                filter: {
                    searchName: filter?.searchString,
                    excludeRevoked: !showRevokedLicenses,
                },
                order,
            })
        } catch (error) {
            addFailedOperation({
                id: 'list-licenses',
                message: 'Failed fetching licenses',
                retryFn: tableRef.current?.refreshData,
            })
            throw error
        }
    }

    const onDeleteSelectedRowsClicked = () => {
        async function deleteSelectedLicenses(licenses: License[]) {
            const licenseIds = licenses.map((l) => l.id)
            try {
                // eslint-disable-next-line no-console
                const { failed: notDeleted } = await billingApi.deleteLicenses({ licenseIds })
                if (notDeleted.length > 0) {
                    const notDeletedLicenses = licenses.filter(({ id }) => notDeleted.includes(id))
                    addFailedOperation({
                        id: notDeleted.join(','),
                        message: `Failed deleting ${notDeleted.length} license(s)`,
                        retryFn: () => void deleteSelectedLicenses(notDeletedLicenses),
                    })
                }
            } catch (error) {
                addFailedOperation({
                    id: licenseIds.join(','),
                    message: `Failed deleting licenses`,
                    error,
                    retryFn: () => void deleteSelectedLicenses(licenses),
                })
            }

            tableRef.current?.refreshData()
        }

        showConfirmDeleteDialogFn(() => {
            void deleteSelectedLicenses(selectedLicenses)
        }, `Delete ${selectedLicenses.length} licenses(s)?`)
    }

    return (
        <>
            <ListPageToolbar
                title={'Licenses'}
                numberOfSelectedItems={selectedLicenses.length}
                selectedActions={[
                    <Tooltip key="delete" title="Delete">
                        <IconButton onClick={onDeleteSelectedRowsClicked}>
                            <Delete />
                        </IconButton>
                    </Tooltip>,
                ]}
                actions={[
                    <RotatingButton
                        key="refresh"
                        tooltip="Refresh"
                        onClick={() => tableRef.current?.refreshData()}
                        Component={<Cached />}
                    />,
                    <RoleBasedComponent key="create" minimumAllowedRole={Role.licenseAdmin}>
                        <NavLink to={Path.createLicense}>
                            <Tooltip title="Create new license">
                                <IconButton>
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        </NavLink>
                    </RoleBasedComponent>,
                ]}
            />
            <Paper>
                <DataFetchingPaginatedTable
                    myRef={tableRef}
                    api={fetchLicenses}
                    columns={columns}
                    emptyRowsMessage={'No licenses found'}
                    onRowClicked={navigateToLicense}
                    onRowsSelected={setSelectedLicenses}
                    boolFilters={[{ filterName: 'showRevoked', label: 'Show revoked licenses', initialValue: false }]}
                    searchParameters={{ searchBarPlaceholder: 'Search by customer, purpose or license key...' }}
                />
            </Paper>

            {errorBanner}
        </>
    )
}
