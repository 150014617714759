import { useState, ReactNode } from 'react'
import { Banner } from '../Banner'
import { formatError } from '../../../format-error'

export interface FailedOperationHook {
    addFailedOperation: (operation: FailedOperation) => void
    removeFailedOperation: (id: string) => void
    errorBanner: ReactNode
}
export interface FailedOperation {
    id: string
    message: string
    error?: Error
    retryFn?: (() => Promise<void>) | (() => void)
}
export function useFailedOperationBanner(): FailedOperationHook {
    const [failedOperations, setFailedOperations] = useState<FailedOperation[]>([])

    const addFailedOperation = (operation: FailedOperation) => {
        setFailedOperations((previousValue) => [...previousValue.filter(({ id }) => id !== operation.id), operation])
    }
    const removeFailedOperation = (id: string) => {
        setFailedOperations((previousValue) => previousValue.filter((op) => op.id !== id))
    }

    const failedOperation = failedOperations[0]
    const reason = failedOperation?.error ? formatError(failedOperation.error) : undefined
    return {
        addFailedOperation,
        removeFailedOperation,
        errorBanner: (
            <>
                {failedOperation && (
                    <Banner
                        id={failedOperation.id}
                        isOpen={true}
                        message={`${failedOperation.message}${
                            reason && reason !== failedOperation.message ? ` - ${reason}` : ''
                        }`}
                        onRetry={
                            failedOperation.retryFn
                                ? async () => {
                                      removeFailedOperation(failedOperation.id)
                                      try {
                                          await failedOperation.retryFn?.()
                                      } catch (error) {
                                          // eslint-disable-next-line no-console
                                          console.error(`hook-failed-operator failed retry: `, error)
                                      }
                                  }
                                : undefined
                        }
                        onClose={() => removeFailedOperation(failedOperation.id)}
                    />
                )}
            </>
        ),
    }
}
