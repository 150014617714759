import { createContext, useContext, ReactNode } from 'react'
import { BillingServerApi } from './billing-api'

const baseUrl = process.env.BILLING_SERVER_URL || ''
// eslint-disable-next-line
console.log(`Billing server url: '${baseUrl}'`)
export const billingApi = new BillingServerApi({ baseUrl })
billingApi
    .getBuildInfo()
    .then((info) => console.log(`Backend build info: ${JSON.stringify(info)}`) /* eslint-disable-line no-console */)
    .catch((e) => console.log(`Failed fetching build info, ${e.toString()}`) /* eslint-disable-line no-console */)

const BillingApiContext = createContext<BillingServerApi>({} as any)
export function BillingApiProvider({ children }: { children: ReactNode }) {
    return <BillingApiContext.Provider value={billingApi}>{children}</BillingApiContext.Provider>
}
export function useBillingApi() {
    return useContext(BillingApiContext)
}
