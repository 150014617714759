import { useMemo, useRef } from 'react'
import Tooltip from '@mui/material/Tooltip'

import { useBillingApi } from '../../api/billing/billing-context'
import { Column, DataFetchingPaginatedTable, RefreshableDataFetchingPaginatedTable } from '../common/Table'
import { Installation, ListInstallationSortableField } from 'common-billing-server/types'
import { formatDateTime } from '../../format-date'
import { InstallationActions } from '../installation/InstallationActions'
import { Query } from 'common/query'
import { PaginatedDataFetchingFilter } from '../common/hooks/hook-paginated-data-fetching'
import { ListResult, SortOrder } from 'common/api/v1/types'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { makeInstallationDetailsPath } from '../../routes/path'
import { useStatefulNavigate } from '../common/hooks/hook-stateful-navigate'

interface Props {
    licenseKey: string
}
export const LicenseInstallations = ({ licenseKey }: Props) => {
    const billingApi = useBillingApi()
    const tableRef = useRef<RefreshableDataFetchingPaginatedTable>(null)
    const { navigate } = useStatefulNavigate()
    const navigateToInstallation = ({ id: installationId, customer }: Installation) =>
        navigate(makeInstallationDetailsPath({ installationId, customerId: customer.id }))
    const { addFailedOperation, errorBanner } = useFailedOperationBanner()

    const columns: Column<Installation, ListInstallationSortableField>[] = useMemo(
        () => [
            {
                title: 'Host',
                valueForColumn: (l) => l.host,
                sortField: ListInstallationSortableField.host,
                isClickable: true,
            },
            {
                title: 'Release',
                valueForColumn: (l) => l.releaseTag,
                sortField: ListInstallationSortableField.releaseTag,
                isClickable: true,
            },
            {
                title: 'Deployment',
                valueForColumn: (l) => l.deploymentProvider,
                sortField: ListInstallationSortableField.deploymentProvider,
                isClickable: true,
            },
            {
                title: 'Created at (UTC)',
                valueForColumn: (l) => {
                    const createdAt = formatDateTime(l.createdAt)
                    const updatedAt = formatDateTime(l.updatedAt)
                    return (
                        <Tooltip title={`Updated: ${updatedAt}`}>
                            <div>{createdAt}</div>
                        </Tooltip>
                    )
                },
            },
            {
                title: 'Last reported (UTC)',
                valueForColumn: (l) =>
                    l.lastReportedPeriodStartAt ? formatDateTime(l.lastReportedPeriodStartAt) : 'N/A',
            },
            {
                title: 'Status',
                valueForColumn: (l) => (l.deleted ? 'Deleted' : 'Active'),
            },
            {
                title: 'Actions',
                isClickable: false,
                valueForColumn: (i) => (
                    <InstallationActions
                        installation={i}
                        refreshTableFn={tableRef.current?.refreshData}
                        addFailedOperation={addFailedOperation}
                    />
                ),
            },
        ],
        []
    )
    async function fetchInstallations({
        filter,
        order,
        ...query
    }: Query<PaginatedDataFetchingFilter<{ showDeleted: boolean }>, SortOrder<ListInstallationSortableField>>): Promise<
        ListResult<Installation>
    > {
        try {
            const showDeletedInstallations = filter?.boolFilters?.showDeleted ?? false
            return await billingApi.listInstallations({
                ...query,
                filter: {
                    licenseKey,
                    searchName: filter?.searchString,
                    excludeDeleted: !showDeletedInstallations,
                },
                order,
            })
        } catch (error) {
            addFailedOperation({
                id: 'list-license-installations',
                message: 'Failed fetching installations for license',
                retryFn: tableRef.current?.refreshData,
            })
            throw error
        }
    }

    return (
        <>
            <DataFetchingPaginatedTable
                myRef={tableRef}
                api={fetchInstallations}
                columns={columns}
                onRowClicked={navigateToInstallation}
                boolFilters={[{ filterName: 'showDeleted', label: 'Show deleted installations', initialValue: true }]}
                searchParameters={{
                    searchBarPlaceholder: 'Search by customer, host, release, deployment or license key...',
                }}
                emptyRowsMessage={'No installations found'}
            />
            {errorBanner}
        </>
    )
}
