import { MouseEvent, ReactNode, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Cancel from '@mui/icons-material/Cancel'
import MoreVert from '@mui/icons-material/MoreVert'
import { styled } from '@mui/material/styles'
import { useStatefulNavigate } from './hooks/hook-stateful-navigate'
import { useBillingApi } from '../../api/billing/billing-context'
import { BillingClient } from 'common-billing-server/generated/billingClient'
import { FailedOperation } from './hooks/hook-failed-operation'
import { Role } from 'common-billing-server/types'
import { useAuth } from '../login/Auth'
import { isRoleAuthorized } from 'common-billing-server/role'

export type TableActionItem = {
    label: string
    icon: ReactNode
    onClick?: RouteOrOpertion
    dataTestId: string
    minimumAllowedRole: Role
}
interface ActionMenuProps {
    actions: TableActionItem[]
    refreshTableFn?: () => void
    addFailedOperation: (failedOp: FailedOperation) => void
}

const ActionSpace = styled('div')({
    display: 'flex',
})

export type RouteOrOpertion = string | ApiOperation

export interface ApiOperation {
    id: string
    name: string
    exec: (apiClient: BillingClient) => Promise<void>
}

export const TableActions = (props: ActionMenuProps) => {
    const { navigate } = useStatefulNavigate()
    const { actions, refreshTableFn, addFailedOperation } = props
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)
    const billingApi = useBillingApi()
    const { user } = useAuth()

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const execOperation = async (op: ApiOperation) => {
        try {
            await op.exec(billingApi)
            if (refreshTableFn) {
                refreshTableFn()
            }
        } catch (e) {
            const message = typeof e.message === 'string' ? e.message : `${op.name} failed`
            addFailedOperation({
                id: op.id,
                message,
                error: e,
            })
        }
    }

    const handleItemClick = (event: MouseEvent<HTMLElement>, routeOrOperation: string | ApiOperation) => {
        event.stopPropagation()
        setAnchorEl(null)
        const route = typeof routeOrOperation === 'string' && routeOrOperation
        const operation = typeof routeOrOperation === 'object' && routeOrOperation
        if (route) {
            navigate(route)
        }
        if (operation) {
            execOperation(operation).catch(() => {
                // noop
            })
        }
    }

    const handleClose = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(null)
    }

    const authorizedActions = actions.filter(
        ({ minimumAllowedRole }) => user && isRoleAuthorized({ role: user.role, minimumAllowedRole })
    )
    if (authorizedActions.length === 0) {
        authorizedActions.push({
            label: 'No actions available',
            dataTestId: 'no-actions-available',
            icon: <Cancel />,
            minimumAllowedRole: Role.invoiceOnly,
        })
    }

    return (
        <ActionSpace>
            <IconButton
                aria-label="open input action menu"
                data-test-id="open-input-action-menu"
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ marginLeft: '-8px' }}
            >
                <MoreVert />
            </IconButton>
            <Menu
                data-test-id="input-action-menu"
                data-is-open={open ? 'true' : 'false'}
                keepMounted
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        minWidth: '35ch',
                    },
                }}
            >
                {authorizedActions.map(({ label, icon, onClick, dataTestId }) => (
                    <MenuItem
                        key={label}
                        aria-label={label}
                        data-test-id={dataTestId}
                        onClick={(e) => onClick && handleItemClick(e, onClick)}
                    >
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText primary={label} />
                    </MenuItem>
                ))}
            </Menu>
        </ActionSpace>
    )
}
